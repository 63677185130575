import { Link } from '@remix-run/react';
import { $path } from 'remix-routes';

import { KotaSymbolAndWordmarkLogo } from '@yonderlabs-packages/brand-assets';

function NotFound() {
  return (
    <div className='flex min-h-screen flex-col bg-yonder-main pb-12 pt-16'>
      <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-shrink-0 justify-center'>
          <Link prefetch='intent' to={$path('/')} className='inline-flex'>
            <KotaSymbolAndWordmarkLogo className='h-12 w-auto' />
          </Link>
        </div>

        <div className='py-16'>
          <div className='text-center'>
            <p className='text-sm font-semibold uppercase tracking-wide text-yonder-600'>404 error</p>
            <h1 className='mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl'>Page not found.</h1>
            <p className='mt-2 text-base text-gray-500'>Sorry, we couldn’t find the page you’re looking for.</p>

            <div className='mt-6'>
              <Link prefetch='intent' to={$path('/')} className='primary-link'>
                Go back home
                <span aria-hidden='true'> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotFound;
